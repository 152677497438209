<template>

    <div>
        <!-- <v-carousel  show-arrows-on-hover v-model="indexSlider" hide-delimiters height="100%"> 
            <v-carousel-item v-for="(item,i) in sliderImg2" :key="i"  >
                <v-img contain :src="item.files" height="100%" width="100%"></v-img>
            </v-carousel-item>
        </v-carousel>  -->
        <v-container>
            <div class="text-center">
                <v-dialog v-model="isLoad" persistent width="300">
                <v-card color="primary" dark>
                    <v-card-text>
                    SUBIENDO IMAGENES ...
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                    </v-card-text>
                </v-card>
                </v-dialog>
            </div>
            <v-card >
                <v-card-title>
                    <v-text-field v-model="search"  prepend-icon="mdi-store-search-outline" label="Buscar imagen"></v-text-field>
                </v-card-title>
                <v-data-table 
                    
                    no-data-text="No hay datos disponibles"
                    no-results-text="No hay coincidencias de tu busqueda"
                    :footer-props="{'items-per-page-options':[10,20,30,40]}"
                    :headers="headers" :items="sliderImg" :search="search">
                    <template ></template>
                    <template v-slot:[`item.orden`]="props">
                        <center>
                            <v-edit-dialog
                            :return-value.sync="props.item.orden"
                            @save="guardarOrden(props.item)"
                            @open="open"
                            >
                                {{ props.item.orden}}
                            <template v-slot:input>
                                <v-text-field
                                v-model="props.item.orden"
                                :rules="[maxNumero]"
                                label="Edit"
                                single-line
                                counter
                                ></v-text-field>
                            </template>
                            </v-edit-dialog>

                        </center>
                    </template>
                     
                <template v-slot:[`item.files`]="{ item }">
                    <center>
                        <br>
                        <v-img
                        style="border: 1px solid #ddd; border-radius:4px; box-shadow:0 0 10px 1px gray;"
                        width="100%"
                        max-height="400px"
                        max-width="400px"
                        :src="item.files"
                        ></v-img>
                        <br>
                    </center>
                </template>
                <template v-slot:[`item.acciones`]="{ item }">
                    <center>
                        <v-icon style="font-size:29px;" color="red" @click="ModalEliminar(item._id)">
                            mdi-delete-alert
                        </v-icon>   
                    </center>
                    <v-dialog v-model="eliminard" max-width="500px" persistent :retain-focus="false">
                        <v-card>
                            <v-card-title class="text-h5">¿Estás seguro de eliminar la imagen?</v-card-title>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red " text @click="eliminard = false">Cancelar</v-btn>
                            <v-btn color="blue " text @click="EliminarImg(arrayEliminar)">Estoy seguro</v-btn>
                            <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-radio-group  mandatory>
                        <v-radio :value="item.status == true" v-if="item.status == true" label="Activo" color="#00FA9A" @click="inactivar(item)">
                        </v-radio>
                        <v-radio :value="item.status == false" v-if="item.status == false" label="Inactivo" color="#FF4500" @click="activar(item)">
                        </v-radio>
                    </v-radio-group>
                </template>
                <template v-slot:[`item.validarFecha`]="{ item }">
                    <center>
                        <div v-if="item.validarFecha == true" label="SI">SI</div>
                        <div v-if="item.validarFecha == false">NO</div>
                    </center>

                    <!-- {{item.validarFecha}} -->
                </template>
                <template  v-slot:[`item.fechaInicio`]="{ item }">
                    <center>
                        <div>{{formatDate(item.fechaInicio)}}</div> 
                    </center>
                </template>
                <template v-slot:[`item.fechaFinal`]="{ item }">
                    <center>
                        <div >{{formatDate(item.fechaFinal)}}</div>
                    </center>
                </template>
                </v-data-table>
                <v-snackbar
                shaped 
                elevation="12"
                v-model="snack"
                :timeout="4000"
                :color="snackColor"
                >
                {{ snackText }}

                    <template v-slot:action="{ attrs }">
                        <v-btn
                        v-bind="attrs"
                        text
                        @click="snack = false"
                        >
                        Close
                        </v-btn>
                    </template>
                </v-snackbar>

            </v-card>
        </v-container>
        <!-- <v-carousel  show-arrows-on-hover v-model="indexSlider" hide-delimiters height="100%"> 
        <v-carousel-item v-for="(item,i) in sliderImg" :key="i"  >
            <v-img contain :src="item" height="100%" width="100%"></v-img>
        </v-carousel-item>
        </v-carousel>  -->
    
    </div>
</template>
<style>
    .v-card .v-data-table-header th[role=columnheader] {
        font-size: 14px !important;
        text-align: center !important;
    }
 
</style>
<script>
import axios from 'axios';
import config from '../../json/config.json'

export default {
    data:() =>({
        snack: false,
        snackColor: '',
        snackText: '',
        maxNumero: v => v.length <= 2 || 'Demasiado largo!',
        addSucursal:false,
        dialog:false,
        search:'',
        headers:[
            {text:'Orden', value:'orden'},
            {text:'Imagen', value:'files'},
            {text:'Estatus',value:'status'},
            {text:'Validar Fecha', value:'validarFecha'},
            {text:'Fecha Inicio', value:'fechaInicio'},
            {text:'Fecha Final',value:'fechaFinal'},
            {text:'Acciones',value:'acciones'}
        ],
        isEdit:false,
        edit:false,
        editarSucursal:[],
        agregarSucursal:[],
        sliderImg:[],
        sliderImg2:[],
        indexSlider: 0,
        total:[],
        isLoad:false,
        eliminard:false,
        arrayEliminar:{},
        item2:[],
        activacion:[],
        editarOrden:[],
    }),
    methods:{
        async MostrarImagenes(){ 
            await axios.get(config.apiAmoresens + "/img/get_img",
            {'headers': { 'token': this.$cookie.get('token') }}
            )
            .then(res=>{
                this.sliderImg = res.data.data;
            })
            .catch(err=>{return err});
            // console.log(this.sliderImg);
            for (var i = 0; i <  this.sliderImg.length; i++) {
                this.total= this.sliderImg[i].files
                // console.log(this.total);
                // this.sliderImg = this.sliderImg[i].files
                // console.log(i);
            }
           
        },
        async EliminarImg(arrayEliminar){
            // console.log(arrayEliminar._id);
            await axios.delete(config.apiAmoresens + `/img/eliminar_img`,
            { headers :{token:this.$cookie.get('token')}, params:{_id:arrayEliminar._id}}
            )
            .then(res=>{
                // console.log(`IMAGEN ELIMINADA`);
                return res.data;
            })
            .catch(err=>{
                return err;
            })
            this.eliminard = false;
            this.MostrarImagenes();
            // this.mostrarimagenes();
        },
        ModalEliminar (_id) {
        this.arrayEliminar._id = _id;
        // console.log(this.arrayEliminar);
        this.eliminard = true;
      },
        async activar(item){
            this.activacion = item;
            // console.log(this.activacion);

            await axios.put(config.apiAmoresens + '/img/activar_img', this.activacion,
            { headers :{token:this.$cookie.get('token')}}
            ).then(result =>{
                return result.data;
            }).catch(err =>{return err;})
            this.MostrarImagenes()
            // this.mostrarimagenes()
        },
        async inactivar(item){
            this.activacion = item; 
            await axios.put(config.apiAmoresens + '/img/inactivar_img', this.activacion,
            { headers :{token:this.$cookie.get('token')}}
            ).then(result =>{
                return result.data;
            }).catch(err =>{return err;})
            this.MostrarImagenes()
            // this.mostrarimagenes()
        },
        formatDate(dates) {
            // const d = new Date(dates) ;
            // return `${d.getDay(dates)} de ${d.getMonth(dates)} de ${d.getFullYear()}`
            const dias = ['Lunes','Martes','Miércoles','Jueves','Viernes','Sábado','Domingo'];
            var month= ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio",
                "Agosto","Septiembre","Octubre","Noviembre","Diciembre"];  
            return `${dias[(new Date(dates)).getDay()]} ${(new Date(dates)).getDate()+1} de ${month[(new Date(Date.parse(dates))).getMonth()]} de ${(new Date(Date.parse(dates))).getFullYear()}`
        },
        async guardarOrden (item) {
            this.editarOrden = item
            this.snack = true
            this.snackColor = '#40E0D0';
            this.snackText = 'DATOS GUARDADOS';
            // console.log(item);
            try{
                await axios.put(config.apiAmoresens + '/img/editar_orden', this.editarOrden,
                {'headers': { 'token': this.$cookie.get('token') }})
                .then(result =>{
                        return result.data;
                }).catch(err =>{return err;})
                
            }catch (error) {
                return error
            }
            this.MostrarImagenes();
            
            
        },
        
        open () {
            this.snack = true
            this.snackColor = 'blue'
            this.snackText = 'EDITAR ORDEN'
        },

        async validaLogin(){
          this.user = await axios.get(config.apiAmoresens + "/user/userByTokenAdmin", 
          {
            'headers': { 'token': this.$cookie.get('token') }
          }).then(res=>{return res.data;})
          .catch(err=>{return err;});
          if (this.user.status == "success") {
            this.user = this.user.user;
            this.isLogged = true; 
          }else if(this.user.status == "unauthorized"){ 
            this.isLogged = false;
          } 
        }
    },
    computed: {
      
    },
    created(){
        this.MostrarImagenes();
        this.validaLogin();
    }
}
</script>   
