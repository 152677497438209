<template>
  <v-main>
    <app-menu />
      <div class="text-center">
        <v-dialog v-model="isLoad" persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              SUBIENDO IMAGENES ...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
         <v-toolbar>    
          <v-tabs v-model="tab"
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
           >
            <v-tabs-slider color="yellow"></v-tabs-slider> 
            <v-tabs v-model="tab" background-color="primary"  grow dark >
            <v-tab>
              Imagenes
            </v-tab> 
            <v-tab>
              Control 
            </v-tab>
            </v-tabs>  
          </v-tabs> 
        </v-toolbar> 
        <v-card min-height="100%" > 
          <v-col   style="text-align:center" v-if="tab==0" >
            <v-alert
              v-model="alerta"
              style="margin:0 auto;"
              color="#40E0D0"
              text
              elevation="24"
              dense
              dismissible
              shaped
              width="30%"
              type="success"
            >¡ SE GUARDÓ LA IMAGEN CORRECTAMENTE !</v-alert>
            <br>
          <v-card  max-width="50%" ref="form" elevation="10" style="margin-top:25px; border-radius: 30px 0px 30px 30px; margin:0 auto;" >
            <v-card color="blue lighten-1" style="border-radius: 30px 0px 180px 0px;">
            <div class="d-flex  justify-center">
              <div>
                <v-card-title class="text-h4">SUBIR</v-card-title> 
                <v-card-subtitle  style="color:white" class="text-h5">Im&aacute;genes</v-card-subtitle> 
              </div> 
              <v-avatar    size="80" tile style=" top:13px;" >
                <v-img  src="../../../public/imagen.png"></v-img>
              </v-avatar>
            </div>
          </v-card> 
            <v-row class="container">
              <v-col>
                <div class="container" >
                  <v-form @submit.prevent="imgUpload(fechaInicio,fechaFinal,validarFecha,orden)" id="imagen" ref="formImg" >
                    <v-row>
                      <v-col
                        cols="12"
                        sm=""
                      >
                        <v-dialog
                          
                          ref="dialogI"
                          v-model="modal"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fechaInicio"
                              label="Fecha de inicio"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                            locale="es"
                            :short-weekdays="false"
                            v-model="fechaInicio"
                            color="red"
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="red"
                              @click="modal = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialogI.save(fechaInicio)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col
                        cols="12"
                        sm=""
                      >
                        <v-dialog
                          ref="dialogF"
                          v-model="modalf"
                          :return-value.sync="fechaFinal"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fechaFinal"
                              label="Fecha final"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker
                            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                            locale="es"
                            v-model="fechaFinal"
                            color="red"
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="red"
                              @click="modalf = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialogF.save(fechaFinal)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                    </v-row>
                    <v-row >
                      <v-col md="3" style="">
                        <v-text-field
                          label="Orden a mostrar"
                          v-model="orden"
                          type="Number"
                          :rules="[(v) => !!v || 'Es requerido' ]"
                          prepend-icon="mdi-order-numeric-ascending"
                          ></v-text-field>
                      </v-col>
                      <v-col md="8" style="margin-left:40px;">
                        <v-radio-group v-model="validarFecha" row  style="margin:0 auto;">
                          <v-card-title class="" style="font-size:15px; color:#00A0F6;">Validar fecha</v-card-title> 
                          <v-radio color="blue" :value="validarfecha = true" label="Si"></v-radio>
                          <v-radio color="red" :value="validarfecha = false" label="No"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-col>
                        <v-file-input
                          class=""
                          v-model="files"
                          color="blue "
                          label="Archivos"
                          multiple
                          placeholder="Selecciona tus imágenes"
                          prepend-icon="mdi-panorama-variant-outline"
                          :show-size="1000"
                          :rules="[(v) => !!v || '¡Elige una imagen porfavor!']"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 5"
                              color="red  accent-2"
                              dark
                              label
                              big
                            >
                              {{ text }}
                            </v-chip>
                            <span
                              v-else-if="index === 5"
                              class="text-overline grey--text text--darken-3 mx-2"
                            >
                              +{{ files.length - 5 }} Archivo(s)
                            </span>
                          </template>
                        </v-file-input>
                    </v-col>
                    <v-col cols="12">
                      <v-col v-if="this.loading == true" style="color:gray;">Guardando...
                      </v-col> 
                      <!-- <v-col v-model="this.guardado" style="color:gray;">¡Se guardó exitosamente!
                      </v-col>  -->
                      <v-progress-linear 
                        :active="loading"
                        :indeterminate="loading"
                        color="blue accent-5"
                        rounded
                        height="10"
                        striped
                      ></v-progress-linear>
                    </v-col>
                      <div justify="center">
                        <v-btn elevation="5" dark type="submit"  class=" mt-10" color="blue lighten-1"
                          ><v-icon style="font-size:22px;" left
                            >mdi-tray-arrow-up</v-icon
                          >Guardar Archivo</v-btn
                        >
                      </div>
                  </v-form>
                </div>
              </v-col>
            </v-row>
          </v-card>
          </v-col>
          <tablaSlider v-if="tab==1" />
        </v-card>
  </v-main>
</template>

<script>
import axios from "axios";
import config from "../../json/config.json";
import AppMenu from '../../components/admin/MenuAdmin.vue'
import tablaSlider from "../../components/admin/TablaSlider.vue";
export default {
  name: "HomeAdmin",
  data() {
    return {
      orden:'',
      fechaInicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fechaFinal: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal:false,
      modalf:false,
      tab : "",
      loading: false,
      guardado:false,
      sliderImg2: [],
      indexSlider: 0,
      files: null,
      imagenes: [],
      sliderImg: [],
      alert: { show: false },
      msgerror: "",
      isLoad: true,
      validarFecha:false,
      alerta:false,
    };
  },

  methods: {
    imgUpload: async function(fechaInicio,fechaFinal,validarFecha,orden) {

      let valid = this.$refs.formImg.validate();
      if(valid){
        this.loading = true;
        let formData = new FormData();
        for (const i of Object.keys(this.files)) {
          formData.append("files", this.files[i]);
        }
       
        await axios
          .post(config.apiAmoresens + `/img/file-upload`,formData, {
            headers: { token: this.$cookie.get("token") }, 
            params: {fechaInicio : fechaInicio,fechaFinal:fechaFinal,validarFecha:validarFecha,orden:orden}
          })
          .then((res) => {
            this.imagenes = res.data;
            
          }).catch(err=>{
            console.log(err);
          })
      }
        this.loading = false;
        this.alerta= true;
    },
  },
  watch: {
      loading (val) {
        if (!val) return

        setTimeout(() => (this.loading = false), 4000)
      },
  },
  components: {
    "app-menu": AppMenu,
      tablaSlider

    // "app-slider": AppSlider,
  },
  async mounted() {
    window.scrollTo(0, 0);
    //
    // this.readRegionname()
    // this.ShowImg();
  },
  computed: {},

  async created() {
    // let date = 
    this.isLoad = true;
    this.user = await axios.get(config.apiAmoresens + "/user/userByTokenAdmin", 
        {'headers': { 'token': this.$cookie.get('token') }})
        .then(res=>{return res.data;})
        .catch(err=>{return err;});     
        if (this.user.status == "success") {
          this.user = this.user.user; 
          this.isLogged = true;
        }else if(this.user.status == "unauthorized"){ 
          this.$router.push('/shop/admin'+config.matchAdmin+'/login');
          this.isLogged = false;
        } 
        
    this.isLoad = false;
  },
};
</script>
